/*! CSS Used from: https://keenthemes.com/metronic/themes/metronic/theme/html/demo7/dist/assets/css/pages/login/classic/login-4.css?v=7.0.3 */

.Teknolab-Logo {
    height: 28vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .Teknolab-Logo {
      animation: Teknolab-Logo-spin infinite 20s linear;
    }
  }

  @keyframes Teknolab-Logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


.login.login-4 .login-forgot,.login.login-4 .login-signin,.login.login-4 .login-signup{display:none;}
.login.login-4.login-signin-on .login-signup{display:none;}
.login.login-4.login-signin-on .login-signin{display:block;}
.login.login-4.login-signin-on .login-forgot{display:none;}
.login.login-4 .login-form{width:100%;max-width:450px;}
@media (max-width:575.98px){
.login.login-4 .login-form{width:100%;max-width:100%;}
}
/*! CSS Used from: https://keenthemes.com/metronic/themes/metronic/theme/html/demo7/dist/assets/plugins/global/plugins.bundle.css?v=7.0.3 */
.fv-plugins-framework input::-ms-clear{display:none;height:0;width:0;}
.fv-plugins-icon-container{position:relative;}
/*! CSS Used from: https://keenthemes.com/metronic/themes/metronic/theme/html/demo7/dist/assets/css/style.bundle.css?v=7.0.3 */
*,::after,::before{-webkit-box-sizing:border-box;box-sizing:border-box;}
body{margin:0;font-family:Poppins,Helvetica,sans-serif;font-size:1rem;font-weight:400;line-height:1.5;color:#464e5f;text-align:left;background-color:#fff;}
h3{margin-top:0;margin-bottom:.5rem;}
a{color:#3699ff;text-decoration:none;background-color:transparent;}
a:hover{color:#0073e9;text-decoration:underline;}
img{vertical-align:middle;border-style:none;}
svg{overflow:hidden;vertical-align:middle;}
label{display:inline-block;margin-bottom:.5rem;}
button{border-radius:0;}
button:focus{outline:1px dotted;outline:5px auto -webkit-focus-ring-color;}
button,input{margin:0;font-family:inherit;font-size:inherit;line-height:inherit;}
button,input{overflow:visible;}
button{text-transform:none;}
button{-webkit-appearance:button;}
button::-moz-focus-inner{padding:0;border-style:none;}
input[type=checkbox]{-webkit-box-sizing:border-box;box-sizing:border-box;padding:0;}
h3{margin-bottom:.5rem;font-weight:500;line-height:1.2;}
h3{font-size:1.5rem;}
@media (max-width:1200px){
h3{font-size:calc(1.275rem + .3vw);}
}
.form-control{display:block;width:100%;height:calc(1.5em + 1.3rem + 2px);padding:.65rem 1rem;font-size:1rem;font-weight:400;line-height:1.5;color:#464e5f;background-color:#fff;background-clip:padding-box;border:1px solid #e5eaee;border-radius:.42rem;-webkit-box-shadow:none;box-shadow:none;-webkit-transition:border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;transition:border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;}
@media (prefers-reduced-motion:reduce){
.form-control{-webkit-transition:none;transition:none;}
}
.form-control::-ms-expand{background-color:transparent;border:0;}
.form-control:-moz-focusring{color:transparent;text-shadow:0 0 0 #464e5f;}
.form-control:focus{color:#464e5f;background-color:#fff;border-color:#69b3ff;outline:0;}
.form-control::-webkit-input-placeholder{color:#b5b5c3;opacity:1;}
.form-control::-moz-placeholder{color:#b5b5c3;opacity:1;}
.form-control:-ms-input-placeholder{color:#b5b5c3;opacity:1;}
.form-control::-ms-input-placeholder{color:#b5b5c3;opacity:1;}
.form-control::placeholder{color:#b5b5c3;opacity:1;}
.form-control:disabled{background-color:#f3f6f9;opacity:1;}
.form-group{margin-bottom:1.75rem;}
.form-text{display:block;margin-top:.25rem;}
.btn{display:inline-block;font-weight:400;color:#464e5f;text-align:center;vertical-align:middle;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-color:transparent;border:1px solid transparent;padding:.65rem 1rem;font-size:1rem;line-height:1.5;border-radius:.42rem;-webkit-transition:color .3s ease-in-out,background-color .3s ease-in-out,border-color .3s ease-in-out,-webkit-box-shadow .3s ease-in-out;transition:color .3s ease-in-out,background-color .3s ease-in-out,border-color .3s ease-in-out,-webkit-box-shadow .3s ease-in-out;transition:color .3s ease-in-out,background-color .3s ease-in-out,border-color .3s ease-in-out,box-shadow .3s ease-in-out;transition:color .3s ease-in-out,background-color .3s ease-in-out,border-color .3s ease-in-out,box-shadow .3s ease-in-out,-webkit-box-shadow .3s ease-in-out;}
@media (prefers-reduced-motion:reduce){
.btn{-webkit-transition:none;transition:none;}
}
.btn:hover{color:#464e5f;text-decoration:none;}
.btn:focus{outline:0;-webkit-box-shadow:none;box-shadow:none;}
.btn:disabled{opacity:.6;-webkit-box-shadow:none;box-shadow:none;}
.btn-primary{color:#fff;background-color:#3699ff;border-color:#3699ff;-webkit-box-shadow:none;box-shadow:none;}
.btn-primary:hover{color:#fff;background-color:#1086ff;border-color:#037fff;}
.btn-primary:focus{color:#fff;background-color:#1086ff;border-color:#037fff;-webkit-box-shadow:0 0 0 .2rem rgba(84,168,255,.5);box-shadow:0 0 0 .2rem rgba(84,168,255,.5);}
.btn-primary:disabled{color:#fff;background-color:#3699ff;border-color:#3699ff;}
.d-flex{display:-webkit-box!important;display:-ms-flexbox!important;display:flex!important;}
.flex-column{-webkit-box-orient:vertical!important;-webkit-box-direction:normal!important;-ms-flex-direction:column!important;flex-direction:column!important;}
.flex-wrap{-ms-flex-wrap:wrap!important;flex-wrap:wrap!important;}
.justify-content-between{-webkit-box-pack:justify!important;-ms-flex-pack:justify!important;justify-content:space-between!important;}
.align-items-center{-webkit-box-align:center!important;-ms-flex-align:center!important;align-items:center!important;}
.overflow-hidden{overflow:hidden!important;}
.position-relative{position:relative!important;}
.h-auto{height:auto!important;}
.m-0{margin:0!important;}
.mx-2{margin-right:.5rem!important;}
.mx-2{margin-left:.5rem!important;}
.my-3{margin-top:.75rem!important;}
.my-3{margin-bottom:.75rem!important;}
.mr-4,.mx-4{margin-right:1rem!important;}
.mx-4{margin-left:1rem!important;}
.mb-5{margin-bottom:1.25rem!important;}
.mt-10{margin-top:2.5rem!important;}
.mb-10{margin-bottom:2.5rem!important;}
.mb-15{margin-bottom:3.75rem!important;}
.mb-20{margin-bottom:5rem!important;}
.py-4{padding-top:1rem!important;}
.py-4{padding-bottom:1rem!important;}
.p-7{padding:1.75rem!important;}
.px-8{padding-right:2rem!important;}
.px-8{padding-left:2rem!important;}
.px-9{padding-right:2.25rem!important;}
.px-9{padding-left:2.25rem!important;}
.text-left{text-align:left!important;}
.text-center{text-align:center!important;}
.font-weight-bold{font-weight:500!important;}
.text-muted{color:#b5b5c3!important;}
@media print{
*,::after,::before{text-shadow:none!important;-webkit-box-shadow:none!important;box-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
img{page-break-inside:avoid;}
h3{orphans:3;widows:3;}
h3{page-break-after:avoid;}
body{min-width:992px!important;}
}
.bgi-size-cover{background-size:cover;}
.bgi-no-repeat{background-repeat:no-repeat;}
.bgi-position-top{background-position:0 top;}
.flex-root{-webkit-box-flex:1;flex:1;-ms-flex:1 0 0px;}
.flex-row-fluid{-webkit-box-flex:1;flex:1 auto;-ms-flex:1 0 0px;min-width:0;}
@media screen and (-ms-high-contrast:active),(-ms-high-contrast:none){
.flex-row-fluid{min-width:none;}
}
.flex-center{-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.opacity-70{opacity:.7!important;}
.h-auto{height:auto!important;}
.h-auto{height:auto!important;}
.max-h-75px{max-height:75px!important;}
.text-hover-primary,a.text-hover-primary{-webkit-transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;}
.text-hover-primary:hover,a.text-hover-primary:hover{-webkit-transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;color:#3699ff!important;}
a{-webkit-transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;}
a:hover{-webkit-transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;}
a,button{outline:0!important;}
.btn{outline:0!important;vertical-align:middle;-webkit-transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;}
.btn:focus:not(.btn-text),.btn:hover:not(.btn-text){-webkit-transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;}
.btn:disabled{-webkit-transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;}
.btn:focus:not(.btn-shadow):not(.btn-shadow-hover){-webkit-box-shadow:none!important;box-shadow:none!important;}
.btn:not(.btn-text){cursor:pointer;}
.btn.btn-primary{color:#fff;background-color:#3699ff;border-color:#3699ff;}
.btn.btn-primary:focus:not(.btn-text){color:#fff;background-color:#187de4;border-color:#187de4;}
.btn.btn-primary:disabled{color:#fff;background-color:#3699ff;border-color:#3699ff;}
.btn.btn-light-primary{color:#3699ff;background-color:#e1f0ff;border-color:transparent;}
.btn.btn-light-primary:focus:not(.btn-text){color:#fff;background-color:#3699ff;border-color:transparent;}
.btn.btn-light-primary:disabled{color:#3699ff;background-color:#e1f0ff;border-color:transparent;}
.form-group label{font-size:1rem;font-weight:400;color:#464e5f;}
.form-group .form-text{font-size:.9rem;font-weight:400;}
.form-control:active,.form-control:focus{-webkit-box-shadow:none!important;box-shadow:none!important;}
.form-control.form-control-solid{background-color:#f3f6f9;border-color:#f3f6f9;color:#464e5f;-webkit-transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;}
.form-control.form-control-solid::-moz-placeholder{color:#b5b5c3;opacity:1;}
.form-control.form-control-solid:-ms-input-placeholder{color:#b5b5c3;}
.form-control.form-control-solid::-webkit-input-placeholder{color:#b5b5c3;}
.form-control.form-control-solid:active,.form-control.form-control-solid:focus{background-color:#ecf0f3;border-color:#ecf0f3;color:#464e5f;-webkit-transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;}
.checkbox{display:inline-block;position:relative;padding-left:25px;text-align:left;cursor:pointer;font-size:1rem;-webkit-transition:all .3s ease;transition:all .3s ease;margin:0;}
.checkbox:hover>input:not([disabled])~span,.checkbox>input:focus~span{-webkit-transition:all .3s ease;transition:all .3s ease;}
.checkbox>input{position:absolute;z-index:-1;opacity:0;}
.checkbox>span{border-radius:.42rem;background-color:none;position:absolute;top:1px;left:0;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;}
.checkbox>span:after{content:'';border-color:transparent;border-style:solid;border-width:0 2px 2px 0!important;-webkit-transform:rotate(45deg);transform:rotate(45deg);margin-top:-2px;}
.checkbox>input:checked~span{-webkit-transition:all .3s ease;transition:all .3s ease;background-color:none;}
.checkbox>input:checked~span:after{display:block;}
.checkbox{top:1px;}
.checkbox>span{height:18px;width:18px;}
.checkbox>span:after{width:5px;height:10px;}
.checkbox>span{background-color:#ecf0f3;border:1px solid transparent;}
.checkbox>input:focus~span{border:1px solid transparent;}
.checkbox>input:checked~span{background-color:#3699ff!important;}
.checkbox>input:checked~span:after{border-color:#fff;}
.fv-plugins-message-container{margin-top:.25rem;}
body{height:100%;margin:0;padding:0;font-size:13px!important;font-weight:400;font-family:Poppins,Helvetica,sans-serif;-ms-text-size-adjust:100%;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
@media (max-width:1199.98px){
body{font-size:12px!important;}
}
@media (max-width:991.98px){
body{font-size:12px!important;}
}
body a:active,body a:focus,body a:hover,html a:active,html a:focus,html a:hover{text-decoration:none!important;}
body{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;color:#464e5f;}
body{background:#ffffff;}
/*! CSS Used from: Embedded */
iframe#_hjRemoteVarsFrame{display:none!important;width:1px!important;height:1px!important;opacity:0!important;pointer-events:none!important;}
/*! CSS Used fontfaces */
@font-face{font-family:'Poppins';font-style:normal;font-weight:300;src:local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLDz8Z11lFc-K.woff2) format('woff2');unicode-range:U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:300;src:local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:300;src:local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:400;src:local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v9/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff2');unicode-range:U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:400;src:local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v9/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:400;src:local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v9/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:500;src:local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLGT9Z11lFc-K.woff2) format('woff2');unicode-range:U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:500;src:local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLGT9Z1JlFc-K.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:500;src:local('Poppins Medium'), local('Poppins-Medium'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:600;src:local('Poppins SemiBold'), local('Poppins-SemiBold'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2) format('woff2');unicode-range:U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:600;src:local('Poppins SemiBold'), local('Poppins-SemiBold'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:600;src:local('Poppins SemiBold'), local('Poppins-SemiBold'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:700;src:local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLCz7Z11lFc-K.woff2) format('woff2');unicode-range:U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:700;src:local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Poppins';font-style:normal;font-weight:700;src:local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v9/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}