/*! CSS Used from: https://keenthemes.com/metronic/themes/metronic/theme/html/demo7/dist/assets/css/style.bundle.css */
*,::after,::before{-webkit-box-sizing:border-box;box-sizing:border-box;}
a{color:#3699ff;text-decoration:none;background-color:transparent;}
a:hover{color:#0073e9;text-decoration:underline;}
.lokasyonlarItemRow{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-top: 77px; margin-right: 25px;margin-left:25px;}
.col-xl-4{position:relative;width:100%;padding-right:12.5px;padding-left:12.5px;}
@media (min-width:1200px){
.col-xl-4{-webkit-box-flex:0;-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%;}
}
.card{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;min-width:0;word-wrap:break-word;background-color:#fff;background-clip:border-box;border:1px solid #ecf0f3;border-radius:.42rem;}
.card-body{-webkit-box-flex:1;-ms-flex:1 1 auto;flex:1 1 auto;min-height:1px;padding:2.25rem;}
.card-title{margin-bottom:2rem;}
.progress{display:-webkit-box;display:-ms-flexbox;display:flex;height:1rem;overflow:hidden;font-size:.75rem;background-color:#ecf0f3;border-radius:.42rem;-webkit-box-shadow:none;box-shadow:none;}
.progress-bar{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;overflow:hidden;color:#fff;text-align:center;white-space:nowrap;background-color:#3699ff;-webkit-transition:width .6s ease;transition:width .6s ease;}
@media (prefers-reduced-motion:reduce){
.progress-bar{-webkit-transition:none;transition:none;}
}
.bg-info{background-color:#8950fc!important;}
.bg-dark{background-color:#212121!important;}
.bg-white{background-color:#fff!important;}
.bg-white{background-color:#fff!important;}
.d-block{display:block!important;}
.mr-2{margin-right:.5rem!important;}
.my-4{margin-top:1rem!important;}
.mb-4,.my-4{margin-bottom:1rem!important;}
.mt-7{margin-top:1.75rem!important;}
.font-weight-bold{font-weight:500!important;}
.font-weight-bolder{font-weight:600!important;}
.text-white{color:#fff!important;}
.text-info{color:#8950fc!important;}
a.text-info:focus,a.text-info:hover{color:#5605fb!important;}
.text-white{color:#fff!important;}
/* a.text-white:focus,a.text-white:hover{color:#d9d9d9!important;} */
.text-dark-75{color:#464e5f!important;}
.text-muted{color:#b5b5c3!important;}
@media print{
*,::after,::before{text-shadow:none!important;-webkit-box-shadow:none!important;box-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
}
.bg-info-o-60{background-color:rgba(137,80,252,.24)!important;}
.bg-white-o-90{background-color:rgba(255,255,255,.36)!important;}
.bgi-no-repeat{background-repeat:no-repeat;}
.text-dark-75{color:#464e5f!important;}
.font-size-sm{font-size:.925rem;}
.font-size-h2{font-size:1.65rem!important;}
.font-size-h6{font-size:1.175rem!important;}
a{-webkit-transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;}
a:hover{-webkit-transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,-webkit-box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;transition:color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease,-webkit-box-shadow .15s ease;}
.card.card-custom{-webkit-box-shadow:0 0 30px 0 rgba(82,63,105,.05);box-shadow:0 0 30px 0 rgba(82,63,105,.05);border:0;}
.card.card-custom>.card-body{padding:1rem 2.25rem;}
.card.card-custom.card-stretch{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:stretch!important;-ms-flex-align:stretch!important;align-items:stretch!important;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;height:100%;}
.card.card-custom.card-stretch.gutter-b{height:calc(100% - 25px);}
a{outline:0!important;}
.gutter-b{margin-bottom:25px;}
.progress.progress-xs{height:.5rem;}
.progress.progress-xs .progress-bar{border-radius:.28rem;}
body a:active,body a:focus,body a:hover,html a:active,html a:focus,html a:hover{text-decoration:none!important;}